import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="お問い合わせ" />
      <div className="flex justify-center items-center p-5 md:p-20 bg-gray-50">
        <h1 className="sm:text-3xl text-2xl font-medium text-gray-600">
          お問い合わせ
        </h1>
      </div>
      <div className="container mx-auto max-w-4xl p-5 md:p-10">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          className="flex flex-col space-y-2 md:space-y-5"
          action="/contact-success"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </div>
          <label className="flex flex-col md:flex-row items-center justify-center">
            <span className="w-full md:w-1/5 mb-1 md:mb-0">会社名</span>
            <input
              required
              type="text"
              name="company"
              className="w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-steel-300 focus:border-transparent px-3 py-2 border border-gray-300"
            />
          </label>
          <label className="flex flex-col md:flex-row items-center justify-center">
            <span className="w-full md:w-1/5 mb-1 md:mb-0">お名前</span>
            <input
              required
              type="text"
              name="name"
              className="w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-steel-300 focus:border-transparent px-3 py-2 border border-gray-300"
            />
          </label>
          <label className="flex flex-col md:flex-row items-center justify-center">
            <span className="w-full md:w-1/5 mb-1 md:mb-0">メールアドレス</span>
            <input
              required
              type="email"
              name="email"
              className="w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-steel-300 focus:border-transparent px-3 py-2 border border-gray-300"
            />
          </label>
          <label className="flex flex-col md:flex-row items-center justify-center">
            <span className="w-full md:w-1/5 mb-1 md:mb-0">お電話番号</span>
            <input
              type="text"
              name="tel"
              className="w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-steel-300 focus:border-transparent px-3 py-2 border border-gray-300"
            />
          </label>
          <label className="flex flex-col md:flex-row items-center justify-center">
            <span className="w-full md:w-1/5 mb-1 md:mb-0">
              お問い合わせ内容
            </span>
            <textarea
              name="message"
              className="w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-steel-300 focus:border-transparent px-3 py-2 border border-gray-300"
            ></textarea>
          </label>
          <div className="flex justify-center mt-5">
            <button
              type="submit"
              className="w-full md:w-1/6 bg-steel-500 hover:bg-steel-400 text-white rounded-sm px-4 py-2 disabled:opacity-70 font-sans font-medium tracking-widest"
            >
              送信
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ContactPage;
